(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.core'], factory) :
    factory();
})((function () {
    (function( window, undefined$1 ) {
        kendo.cultures["nb-NO"] = {
            name: "nb-NO",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": " ",
                ".": ",",
                groupSize: [3],
                percent: {
                    pattern: ["-n %","n %"],
                    decimals: 2,
                    ",": " ",
                    ".": ",",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "Norwegian Krone",
                    abbr: "NOK",
                    pattern: ["-$ n","$ n"],
                    decimals: 2,
                    ",": " ",
                    ".": ",",
                    groupSize: [3],
                    symbol: "kr"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["søndag","mandag","tirsdag","onsdag","torsdag","fredag","lørdag"],
                        namesAbbr: ["søn.","man.","tir.","ons.","tor.","fre.","lør."],
                        namesShort: ["sø.","ma.","ti.","on.","to.","fr.","lø."]
                    },
                    months: {
                        names: ["januar","februar","mars","april","mai","juni","juli","august","september","oktober","november","desember"],
                        namesAbbr: ["jan","feb","mar","apr","mai","jun","jul","aug","sep","okt","nov","des"]
                    },
                    AM: ["a.m.","a.m.","A.M."],
                    PM: ["p.m.","p.m.","P.M."],
                    patterns: {
                        d: "dd.MM.yyyy",
                        D: "dddd d. MMMM yyyy",
                        F: "dddd d. MMMM yyyy HH:mm:ss",
                        g: "dd.MM.yyyy HH:mm",
                        G: "dd.MM.yyyy HH:mm:ss",
                        m: "d. MMMM",
                        M: "d. MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "HH:mm",
                        T: "HH:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM yyyy",
                        Y: "MMMM yyyy"
                    },
                    "/": ".",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

}));
